import React from 'react';

import { About, Seo, SeoHidden } from 'components';

export default () => (
  <>
    <Seo
      title="Services"
      description="Strategy and digital. We help to start and change. We combine client's industry experience and our expertise in digital. We introduce new work approaches and digital technologies to companies, develop the market and specialists."
    />
    <SeoHidden>
      <h1>Services</h1>
    </SeoHidden>
    <About />
  </>
);
